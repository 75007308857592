import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import Check from "../../icons/Check";
import { LABEL_PLACEMENT } from "./constants";

const StyledCheckbox = styled.label`
  display: flex;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  flex-direction: ${props => (props.labelPlacement === LABEL_PLACEMENT.top || props.labelPlacement === LABEL_PLACEMENT.bottom ? "column" : "row")};
  align-items: ${props => (props.labelPlacement === LABEL_PLACEMENT.top || props.labelPlacement === LABEL_PLACEMENT.bottom ? "center" : "flex-start")};
  background: ${props => (props.styleName ? "#F6F7F8" : "transparent")};
  border-radius: ${props => (props.styleName ? "8px" : "0")};
`;

function getBoxBackgroundColor(props) {
  const { disabled, checked, error, theme } = props;

  if (disabled) {
    return theme.colors.tickFillDisabled;
  }
  if (checked) {
    if (error) {
      return theme.colors.tickFillErrorSelected;
    }
    return theme.colors.tickFillSelected;
  }
  if (error) {
    return theme.colors.tickFillError;
  }
  return theme.colors.tickFill;
}

function getBoxBorderColor(props) {
  const { disabled, checked, error, theme } = props;

  if (disabled) {
    return theme.colors.tickFillDisabled;
  }
  if (checked) {
    if (error) {
      return theme.colors.tickBorderError;
    }
    return "transparent";
  }
  if (error) {
    return theme.colors.tickBorderError;
  }
  return theme.colors.tickBorder;
}

function getTickColor(props) {
  const { disabled, error, theme } = props;

  if (disabled) {
    return theme.colors.tickMarkFillDisabled;
  }
  if (error) {
    return theme.colors.tickMarkFillError;
  }
  return theme.colors.tickMarkFill;
}

function getBoxBorderRadius(props) {
  const { styleName, theme } = props;
  
  if (styleName) {
    return theme.borders.radius200;
  }
  return "100%";
}

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  min-width: 24px;
  height: 24px;
  border-style: solid;
  border-width: 1px;
  border-radius: ${getBoxBorderRadius};
  border-color: ${getBoxBorderColor};
  background-color: ${getBoxBackgroundColor};
  ${props => props.theme.animations.easeOutCurve};
  svg {
    color: ${getTickColor};
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  margin: 0;
  width: 0;
  height: 0;
  padding: 0;
  position: absolute;
  border-radius: 100%;
  overflow: hidden;
`;

function getLabelPadding(props) {
  const { labelPlacement } = props;

  switch (labelPlacement) {
    case LABEL_PLACEMENT.top:
      return css`
        padding-bottom: 8px;
      `;
    default:
    case LABEL_PLACEMENT.right:
      return css`
        padding-left: 8px;
      `;
    case LABEL_PLACEMENT.bottom:
      return css`
        padding-top: 8px;
      `;
    case LABEL_PLACEMENT.left:
      return css`
        padding-right: 8px;
      `;
  }
}

const Label = styled.div`
  color: ${props => (props.disabled ? props.theme.colors.contentSecondary : props.theme.colors.contentPrimary)};
  ${props => props.theme.typography.font300};
  ${getLabelPadding};
`;

function Checkbox(props) {
  const { labelPlacement, children, name, checked, disabled, error, onChange, onBlur, styleName = null } = props;
  const label = (
    <Label disabled={disabled} labelPlacement={labelPlacement}>
      {children}
    </Label>
  );

  return (
    <StyledCheckbox disabled={disabled} labelPlacement={labelPlacement} styleName={styleName} data-naga-components="checkbox">
      {(labelPlacement === LABEL_PLACEMENT.top || labelPlacement === LABEL_PLACEMENT.left) && label}
      <Box checked={checked} disabled={disabled} error={error}>
        {checked && <Check size={16} />}
      </Box>
      <StyledInput type="checkbox" name={name} checked={checked} disabled={disabled} onChange={onChange} onBlur={onBlur} />
      {(labelPlacement === LABEL_PLACEMENT.right || labelPlacement === LABEL_PLACEMENT.bottom) && label}
    </StyledCheckbox>
  );
}

Checkbox.propTypes = {
  labelPlacement: PropTypes.oneOf([LABEL_PLACEMENT.top, LABEL_PLACEMENT.right, LABEL_PLACEMENT.bottom, LABEL_PLACEMENT.left]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

Checkbox.defaultProps = {
  labelPlacement: LABEL_PLACEMENT.right,
  checked: false,
  disabled: false,
  error: false,
};

export default Checkbox;
