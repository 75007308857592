import React from "react";
import styled from "styled-components";

const StyledModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${(props) => props.theme.naga.colors.backgroundSecondary};
  border-top: 1px solid ${(props) => props.theme.naga.colors.inputBorderDefault};
  padding-top: ${(props) => props.theme.naga.spacing.padding.medium};
  padding-right: ${(props) => props.theme.naga.spacing.padding.large};
  padding-bottom: ${(props) => props.theme.naga.spacing.padding.medium};
  padding-left: ${(props) => props.theme.naga.spacing.padding.large};
  color: ${(props) => props.theme.naga.colors.contentPrimary};
`;

type props = {
  children?: React.ReactNode;
};

function ModalFooter(props: props): React.ReactElement {
  const { children } = props;

  return <StyledModalFooter>{children}</StyledModalFooter>;
}

export default ModalFooter;
