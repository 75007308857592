const screenSize = {
  mobileResolutionSmallDevicesMaxWidth: "360px",
  mobileResolutionMaxWidth: "768px",
  desktopResolutionminWidth: "769px",
  largeDevicesMaxWidth: "992px",
  tabletDevicesMaxWidth: "1024px",
  desktopResolutionMaxWidth: "1280px",
  mediumDesktopResolutionMaxWidth: "1366px",
};

export default screenSize;