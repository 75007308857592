import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { LABEL_PLACEMENT } from "./constants";

const StyledCheckbox = styled.label`
  display: flex;
  flex-direction: column;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  align-items: center;
  justify-content: center;
  background: ${props => (props.checked ? props.theme.colors.accent : "rgba(152, 188, 255, 0.2)")};
  color: ${props => (props.checked ? props.theme.colors.buttonSecondarySelectedText : props.theme.colors.primary)};
  border-radius: 8px;
  height: 100%;
  width: 100%;
  margin-bottom: 16px;
  span {
    ${props => props.theme.typography.font350};
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  margin: 0;
  width: 0;
  height: 0;
  padding: 0;
  position: absolute;
  border-radius: 100%;
  overflow: hidden;
`;

function MultiCheckbox(props) {
  const { labelPlacement, children, name, checked, disabled, error, onChange, onBlur } = props;

  return (
    <StyledCheckbox disabled={disabled} labelPlacement={labelPlacement} data-naga-components="checkbox" checked={checked}>
      <span>{children}</span>
      <StyledInput type="checkbox" name={name} checked={checked} disabled={disabled} onChange={onChange} onBlur={onBlur} />
    </StyledCheckbox>
  );
}

MultiCheckbox.propTypes = {
  labelPlacement: PropTypes.oneOf([LABEL_PLACEMENT.top, LABEL_PLACEMENT.right, LABEL_PLACEMENT.bottom, LABEL_PLACEMENT.left]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

MultiCheckbox.defaultProps = {
  labelPlacement: LABEL_PLACEMENT.right,
  checked: false,
  disabled: false,
  error: false,
};

export default MultiCheckbox;
