import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";
import { useFormik } from "formik";

import themes from "./themes";
import GlobalStyle from "./GlobalStyle";

import constants from "./constants";

import { Button, KIND } from "./components/button";
import { ParagraphMedium } from "./components/typography";
import Debug from "./Debug";

const Header = styled.header`
  margin-bottom: 1rem;
`;

const Footer = styled.footer`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
`;

function defaultHeader(props) {
  const { totalSteps, stepNumber } = props;

  return (
    <Header>
      <ParagraphMedium>
        Step {stepNumber + 1} of {totalSteps}
      </ParagraphMedium>
    </Header>
  );
}

function defaultFooter(props) {
  const { stepNumber, previous, isSubmitting, isLastStep } = props;

  return (
    <Footer>
      {stepNumber > 0 && (
        <Button onClick={previous} type="button" kind={KIND.secondary}>
          <span>Back</span>
        </Button>
      )}
      <Button disabled={isSubmitting}>
        <span>{isLastStep ? "Submit" : "Next"}</span>
      </Button>
    </Footer>
  );
}

function StatefulWizard(props) {
  const {
    children,
    initialValues,
    onSubmit,
    darkMode,
    debug,
    className,
    enableReinitialize,
    header,
    footer,
    disabledFields,
    hiddenFields,
    tooltipTitle,
    options,
    onChange,
    onPrevious,
    onNext,
    labels,
    isLoading,
    handleLabel,
    handleOptionLabel,
  } = props;

  const [stepNumber, setStepNumber] = React.useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = React.useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;
  const theme = darkMode ? themes.dark : themes.light;

  const formik = useFormik({
    enableReinitialize,
    initialValues: snapshot,
    onSubmit: handleSubmit,
    validationSchema: step && step.props.validationSchema,
  });

  function next(values) {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  }

  function previous(values) {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  }

  function handleSubmit() {
    if (step.props.onSubmit) {
      step.props.onSubmit(formik);
    }
    if (isLastStep) {
      return onSubmit(formik);
    } else {
      if (typeof onNext === "function") {
        onNext({ totalSteps, stepNumber, isLastStep, formik });
      }
      next(formik.values);
      formik.setTouched({});
    }
  }

  function handlePrevious() {
    if (typeof onPrevious === "function") {
      onPrevious({ totalSteps, stepNumber, isLastStep, formik });
    }
    previous(formik.values);
  }

  React.useEffect(() => {
    if (enableReinitialize) {
      setSnapshot(initialValues);
    }
  }, [initialValues, enableReinitialize]);

  React.useEffect(() => {
    if (typeof onChange === "function") {
      onChange(formik);
    }
  }, [formik.values]);

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={formik.handleSubmit} className={className}>
        {typeof header === "function" &&
          header({ totalSteps, stepNumber, formik })}
        {React.isValidElement(step) &&
          React.cloneElement(step, {
            formik,
            disabledFields,
            hiddenFields,
            tooltipTitle,
            options,
            labels,
            isLoading,
            handleLabel,
            handleOptionLabel,
          })}
        {typeof footer === "function" &&
          footer({
            stepNumber,
            previous: handlePrevious,
            isSubmitting: formik.isSubmitting,
            isLastStep,
            formik,
          })}
      </form>
      {debug && constants.IS_DEV && <Debug formik={formik} />}
      <GlobalStyle />
    </ThemeProvider>
  );
}

StatefulWizard.propTypes = {
  fields: PropTypes.array,
  darkMode: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  debug: PropTypes.bool,
  className: PropTypes.string,
  enableReinitialize: PropTypes.bool,
  header: PropTypes.func,
  footer: PropTypes.func,
  disabledFields: PropTypes.array,
  hiddenFields: PropTypes.array,
  tooltipTitle: PropTypes.string,
  options: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  labels: PropTypes.object,
  isLoading: PropTypes.object,
  handleLabel: PropTypes.func,
  handleOptionLabel: PropTypes.func,
};

StatefulWizard.defaultProps = {
  fields: [],
  darkMode: false,
  debug: false,
  enableReinitialize: true,
  header: defaultHeader,
  footer: defaultFooter,
  disabledFields: [],
  hiddenFields: [],
  tooltipTitle: "Help",
  options: {},
  labels: {},
  isLoading: {},
};

export default StatefulWizard;
