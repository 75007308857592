import typography from "./typography";
import animations from "./animations";
import borders from "./borders";

const theme = {
  colors: {
    primary: "#000000",
    accent: "#3179FF",
    background: "#FFFFFF",
    backgroundPrimary: "#FFFFFF",
    backgroundSecondary: "#FFFFFF",
    backgroundInversePrimary: "#000000",
    backgroundTertiary: "#F3F7FA",
    contentPrimary: "#000000",
    contentSecondary: "#545454",
    negative: "#D44333",
    warning: "#FFC043",
    positive: "#3AA76D",
    foreground: "#212B36",
    foregroundAlt: "#545454",
    border: "#CBCBCB",
    borderFocus: "#000000",
    buttonPrimaryFill: "#3179ff",
    buttonPrimarySelectedFill: "#545454",
    buttonPrimaryText: "#FFFFFF",
    buttonPrimarySelectedText: "#FFFFFF",
    buttonSecondaryFill: "transparent",
    buttonSecondarySelectedFill: "#3179ff",
    buttonSecondaryText: "#000000",
    buttonSecondarySelectedText: "#FFFFFF",
    buttonDisabledFill: "#F6F6F6",
    buttonDisabledText: "#AFAFAF",
    inputFill: "#FFFFFF",
    inputBorder: "#DFE3E9",
    inputFillDisabled: "#F6F6F6",
    inputTextDisabled: "#AFAFAF",
    inputFillError: "#FFFFFF",
    inputBorderError: "#EE3142",
    inputFillPositive: "#F0F9F4",
    inputBorderPositive: "#AEDDC2",
    inputFillActive: "#F6F6F6",
    notificationInfoBackground: "#EEF3FE",
    notificationInfoText: "#1E54B7",
    notificationPositiveBackground: "#F0F9F4",
    notificationPositiveText: "#368759",
    notificationWarningBackground: "#FEF3EF",
    notificationWarningText: "#B45427",
    notificationNegativeBackground: "#FDF0EF",
    notificationNegativeText: "#AE372A",
    menuFill: "#FFFFFF",
    menuFillHover: "#F6F6F6",
    tickBorder: "#757575",
    tickFillSelected: "#000000",
    tickFill: "#FFFFFF",
    tickMarkFill: "#FFFFFF",
    tickFillErrorSelected: "#E11900",
    tickBorderError: "#E11900",
    tickFillError: "#FFEFED",
    tickFillDisabled: "#AFAFAF",
    tickMarkFillDisabled: "#FFFFFF",
    tickMarkFillError: "#FFFFFF",
  },
  typography,
  animations,
  borders,
};

export default theme;
