import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ALIGN } from "./constants";

function getOuterBackgroundColor(props) {
  const { disabled, checked, error, theme } = props;

  if (disabled) {
    return theme.colors.tickFillDisabled;
  }
  if (checked) {
    if (error) {
      return theme.colors.tickFillErrorSelected;
    }
    return theme.colors.tickFillSelected;
  }
  if (error) {
    return theme.colors.tickBorderError;
  }
  return theme.colors.tickBorder;
}

function getInnerBackgroundColor(props) {
  const { disabled, checked, error, theme } = props;

  if (disabled) {
    return theme.colors.tickMarkFillDisabled;
  }
  if (checked) {
    return theme.colors.tickMarkFill;
  }
  if (error) {
    return theme.colors.tickFillError;
  }
  return theme.colors.tickFill;
}

const StyledRadio = styled.label`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: ${(props) =>
    props.align === ALIGN.horizontal ? "6px 6px 6px 0" : "6px 0"};
`;

const RadioMarkOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  outline: none;
  margin: 2px;
  background-color: ${getOuterBackgroundColor};
  ${(props) => props.theme.animations.easeOutCurve};
`;

const RadioMarkInner = styled.div`
  border-radius: 50%;
  width: ${(props) => (props.checked ? "6px" : "14px")};
  height: ${(props) => (props.checked ? "6px" : "14px")};
  background-color: ${getInnerBackgroundColor};
  ${(props) => props.theme.animations.easeOutCurve};
`;

const StyledInput = styled.input`
  opacity: 0;
  margin: 0;
  width: 0;
  height: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
`;

const Label = styled.div`
  padding-left: 8px;
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.contentSecondary
      : props.theme.colors.contentPrimary};
  ${(props) => props.theme.typography.font300};
`;

function Radio(props) {
  const {
    align,
    name,
    checked,
    onChange,
    disabled,
    error,
    value,
    children,
  } = props;
  const markProps = { disabled, checked, error };

  return (
    <StyledRadio align={align}>
      <RadioMarkOuter {...markProps}>
        <RadioMarkInner {...markProps} />
      </RadioMarkOuter>
      <StyledInput
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
      <Label>{children}</Label>
    </StyledRadio>
  );
}

Radio.propTypes = {
  align: PropTypes.oneOf([ALIGN.vertical, ALIGN.horizontal]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

Radio.defaultProps = {
  align: ALIGN.vertical,
  disabled: false,
  error: false,
};

export default Radio;
