import moment from "moment";
import { VALIDATE_YEAR } from "./constants";

export const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

export const padNumber = num => String(num).padStart(2, "0");

export const getMonths = Array.from({ length: 12 }, (item, i) => {
  const label = new Date(0, i).toLocaleString("en-US", { month: "long" });
  const value = new Date(0, i).toLocaleString("en-US", { month: "numeric" });

  return { label, value: padNumber(value) };
});

export const validateDay = day => !day || Number(day) < 1;

// year needs to match the regex first and then check if entered number is less than 18 or more than 100. same validation as on web app
export const validateYear = year => !VALIDATE_YEAR.test(year) || moment().diff(moment(year, 'YYYY-MM-DD'), 'years') > 100 || moment().diff(moment(year, 'YYYY-MM-DD'), 'years') < 18;