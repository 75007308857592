const BREAKPOINTS = {
  SMALL_DEVICES: "576px",
  MEDIUM_DEVICES: "768px",
  LARGE_DEVICES: "992px",
  EXTRA_LARGE_DEVICES: "1200px",
};
const IS_DEV = process.env.NODE_ENV === "development";

export default {
  BREAKPOINTS,
  IS_DEV,
};
