import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const CheckNeutral = forwardRef(
  ({ color = "currentColor", size = 24, ...rest }, ref) => {
    return (
      <svg ref={ref} width={size} height={size} viewBox="0 0 89 89" {...rest}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="D-Verify-Account---Finish-Upload---Dark"
            transform="translate(-307.000000, -479.000000)"
            stroke="#3179FF"
            strokeWidth="3"
          >
            <g transform="translate(309.000000, 481.000000)">
              <circle cx="42.5" cy="42.5" r="42.5"></circle>
              <path
                d="M26,43.1335056 L35.2672999,52.4008055 C35.6578242,52.7913298 36.2909892,52.7913298 36.6815135,52.4008055 L58.082319,31 L58.082319,31"
                strokeLinecap="round"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
);

CheckNeutral.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CheckNeutral.displayName = "CheckNeutral";

export default CheckNeutral;
