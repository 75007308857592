import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { KIND, SIZE, SHAPE } from "./constants";
import constants from "../../constants";

function getFontStyles(props) {
  const { size } = props;

  switch (size) {
    default:
    case SIZE.default:
      return props.theme.typography.font300;
    case SIZE.compact:
      return props.theme.typography.font200;
    case SIZE.large:
      return props.theme.typography.font450;
  }
}

function getKindStyles(props) {
  const { kind, isSelected } = props;

  switch (kind) {
    default:
    case KIND.primary:
      if (isSelected) {
        return css`
          background-color: ${props.theme.colors.buttonPrimarySelectedFill};
          border-radius: ${props => props.theme.borders.radius300};
          color: ${props.theme.colors.buttonPrimarySelectedText};
        `;
      }
      return css`
        background-color: ${props.theme.colors.buttonPrimaryFill};
        color: ${props.theme.colors.buttonPrimaryText};
      `;
    case KIND.secondary:
      if (isSelected) {
        return css`
          background-color: ${props.theme.colors.buttonSecondarySelectedFill};
          color: ${props.theme.colors.buttonSecondarySelectedText};
          border-radius: ${props => props.theme.borders.radius300};
          border-color: ${props.theme.colors.buttonSecondarySelectedFill};
        `;
      }
      return css`
        background-color: ${props.theme.colors.buttonSecondaryFill};
        color: ${props.theme.colors.buttonSecondaryText};
      `;
    case KIND.tertiary:
      if (isSelected) {
        return css`
          background-color: ${props.theme.colors.buttonSecondarySelectedFill};
          color: ${props.theme.colors.buttonSecondarySelectedText};
          border-radius: ${props => props.theme.borders.radius300};
          border-color: ${props.theme.colors.buttonSecondarySelectedFill};
          margin-bottom: 16px;
          padding: 22px 24px;
          width: 350px;
          @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
            width: 390px;
          }
        `;
      }
      return css`
        background-color: ${props.theme.colors.buttonTertiaryFill};
        border-radius: ${props => props.theme.borders.radius300};
        color: ${props.theme.colors.buttonTertiaryText};
        margin-bottom: 16px;
        padding: 22px 24px;
        width: 350px;
        @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
          width: 390px;
        }
      `;
  }
}

function getShapeStyles(props) {
  const { shape } = props;

  switch (shape) {
    case SHAPE.pill:
      return css`
        border-radius: ${props => props.theme.borders.radius400};
      `;
    case SHAPE.default:
    default:
      return css`
        border-radius: ${props => props.theme.borders.radius200};
      `;
  }
}

const StyledButton = styled.button`
  border: 1px solid transparent;
  cursor: pointer;
  outline: 0;
  padding: 22px 50px;
  border-radius: 8px;
  :disabled {
    cursor: not-allowed;
  }
  :hover {
    @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
      background-color: ${props => (!props.isSelected ? props.theme.colors.buttonHoverFill : null)};
    }
  }
  ${getFontStyles};
  ${getKindStyles};
  ${getShapeStyles};
`;

function Button(props) {
  const { isLoading, onClick, children } = props;

  function internalOnClick() {
    if (!isLoading) {
      onClick();
    }
  }

  return (
    <StyledButton {...props} onClick={internalOnClick} data-naga-components="button">
      {children}
    </StyledButton>
  );
}

Button.propTypes = {
  kind: PropTypes.oneOf([KIND.primary, KIND.secondary, KIND.tertiary]),
  size: PropTypes.oneOf([SIZE.default, SIZE.compact, SIZE.large]),
  shape: PropTypes.oneOf([SHAPE.default, SHAPE.pill]),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  kind: KIND.primary,
  size: SIZE.default,
  shape: SHAPE.default,
  disabled: false,
  isLoading: false,
  isSelected: false,
  onClick: () => {},
  type: "submit",
};

export default Button;
