import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ALIGN } from "./constants";

const StyledRadioGroup = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.align === ALIGN.horizontal ? "row" : "column"};
  align-items: ${(props) =>
    props.align === ALIGN.horizontal ? "center" : "flex-start"};
`;

function RadioGroup(props) {
  const { align, children, name, onChange, disabled, error, value } = props;

  return (
    <StyledRadioGroup align={align} data-naga-components="radio">
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
          align,
          name,
          onChange,
          disabled,
          error,
          checked: value === child.props.value,
        });
      })}
    </StyledRadioGroup>
  );
}

RadioGroup.propTypes = {
  align: PropTypes.oneOf([ALIGN.vertical, ALIGN.horizontal]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};

RadioGroup.defaultProps = {
  align: ALIGN.vertical,
  disabled: false,
  error: false,
};

export default RadioGroup;
