import React from "react";
import styled from "styled-components";

const StyledModalBody = styled.div`
  padding-top: ${(props) => props.theme.naga.spacing.padding.medium};
  padding-right: ${(props) => props.theme.naga.spacing.padding.large};
  padding-bottom: ${(props) => props.theme.naga.spacing.padding.large};
  padding-left: ${(props) => props.theme.naga.spacing.padding.large};
  color: ${(props) => props.theme.naga.colors.contentPrimary};
`;

type props = {
  children?: React.ReactNode;
};

function ModalBody(props: props): React.ReactElement {
  const { children } = props;

  return <StyledModalBody>{children}</StyledModalBody>;
}

export default ModalBody;
