import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import constants from "../../constants";
import { YEAR_FORMAT, DAY_FORMAT } from "./constants";
import { getDaysInMonth, getMonths, padNumber, validateDay, validateYear } from "./helpers";

import { Select } from "../select";
import { Input } from "../input";
import { FormControl } from "../form-control";

const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 154px 84px 84px;
  width: 354px;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    grid-template-columns: 174px 91px 91px;
    width: 392px;
  }
`;

function Birthday(props) {
  const { value, onChange, disabled, options, labels } = props;
  const monthOptions = options || getMonths;
  const dateValue = value ? new Date(value) : null;
  const [month, setMonth] = React.useState((dateValue && monthOptions[dateValue.getMonth()]) || {});
  const [day, setDay] = React.useState((dateValue && dateValue.getDate()) || "");
  const [year, setYear] = React.useState((dateValue && dateValue.getFullYear()) || "");

  const [errors, setErrors] = React.useState({
    day: false,
    month: false,
    year: false,
  });

  React.useEffect(() => {
    if (month.value !== "" && day && year) {
      const monthNumber = month.value;
      onChange(`${year}-${monthNumber}-${padNumber(day)}`);
    } else {
      onChange("");
    }
  }, [month, day, year]);

  React.useEffect(() => {
    if (month && year && day) {
      const monthNumber = month.value;
      const numberOfDays = getDaysInMonth(monthNumber, year);
      if (validateDay(day)) {
        setErrors({ ...errors, day: true });
        return;
      }
      if (day > numberOfDays) {
        setErrors({ ...errors, day: true });
      } else {
        setErrors({ ...errors, day: false });
      }
    }
  }, [month, year, day]);

  const onYearChange = val => {
    if (!YEAR_FORMAT.test(val)) return;
    setYear(val);
  };

  const onDayChange = el => {
    if (!DAY_FORMAT.test(el)) return;
    setDay(el);
  };

  const formatYear = () => {
    if (year && validateYear(year)) {
      setErrors({ ...errors, year: true });
    } else {
      setErrors({ ...errors, year: false });
    }
  };

  const formatDay = () => {
    if (validateDay(day)) {
      return;
    }
    setDay(day);
  };

  return (
    <Wrapper data-naga-components="birthday">
      <div>
        <FormControl error={errors.month} disabled={disabled}>
          <Select labelAdditional={labels.month} name="month" width="174px" mobileWidth="154px" options={monthOptions} value={month} onChange={value => setMonth(value)} />
        </FormControl>
      </div>
      <div>
        <FormControl error={errors.day} disabled={disabled}>
          <Input labelAdditional={labels.day} name="day" type="text" width="100%" mobileWidth="100%" value={day} onBlur={formatDay} onChange={e => onDayChange(e.currentTarget.value)} />
        </FormControl>
      </div>
      <div>
        <FormControl error={errors.year} disabled={disabled}>
          <Input labelAdditional={labels.year} name="year" type="text" width="100%" mobileWidth="100%" value={year} onBlur={formatYear} onChange={e => onYearChange(e.currentTarget.value)} />
        </FormControl>
      </div>
    </Wrapper>
  );
}

Birthday.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  labels: PropTypes.object,
};

export default Birthday;
