import typography from "./typography";
import animations from "./animations";
import borders from "./borders";

const theme = {
  colors: {
    primary: "#FFFFFF",
    accent: "#3179FF",
    background: "#000000",
    backgroundPrimary: "#141414",
    backgroundSecondary: "#252B40",
    backgroundInversePrimary: "#E2E2E2",
    backgroundTertiary: "#2A2D3A",
    contentPrimary: "#E2E2E2",
    contentSecondary: "#AFAFAF",
    negative: "#D44333",
    warning: "#C19132",
    positive: "#3AA76D",
    foreground: "#CBCBCB",
    foregroundAlt: "#757575",
    border: "#292929",
    borderFocus: "#FFFFFF",
    buttonPrimaryFill: "#3179ff",
    buttonPrimarySelectedFill: "#E2E2E2",
    buttonPrimaryText: "#FFFFFF",
    buttonPrimarySelectedText: "#000000",
    buttonSecondaryFill: "transparent",
    buttonSecondarySelectedFill: "#3179ff",
    buttonSecondaryText: "#FFFFFF",
    buttonSecondarySelectedText: "#FFFFFF",
    buttonDisabledFill: "#292929",
    buttonDisabledText: "#757575",
    inputFill: "#252B40",
    inputBorder: "#20283C",
    inputFillDisabled: "#141414",
    inputTextDisabled: "#333333",
    inputFillError: "#252B40",
    inputBorderError: "#EE3142",
    inputFillPositive: "#1C472F",
    inputBorderPositive: "#3AA76D",
    inputFillActive: "#333333",
    notificationInfoBackground: "#102C60",
    notificationInfoText: "#A0BFF9",
    notificationPositiveBackground: "#1C472F",
    notificationPositiveText: "#AEDDC2",
    notificationWarningBackground: "#5F2C14",
    notificationWarningText: "#F7BFA5",
    notificationNegativeBackground: "#5C1D16",
    notificationNegativeText: "#F4AFA7",
    menuFill: "#292929",
    menuFillHover: "#1F1F1F",
    tickBorder: "#757575",
    tickFillSelected: "#FFFFFF",
    tickFill: "#000000",
    tickMarkFill: "#000000",
    tickFillErrorSelected: "#AB1300",
    tickBorderError: "#E11900",
    tickFillError: "#5A0A00",
    tickFillDisabled: "#333333",
    tickMarkFillDisabled: "#141414",
    tickMarkFillError: "#FFFFFF",
  },
  typography,
  animations,
  borders,
};

export default theme;
