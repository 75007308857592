export const KIND = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
};
export const SIZE = {
  default: "default",
  compact: "compact",
  large: "large",
};
export const SHAPE = {
  default: "default",
  pill: "pill",
};
