import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const UploadCloud = forwardRef(
  ({ color = "currentColor", size = 24, ...rest }, ref) => {
    return (
      <svg ref={ref} width="62px" height="42px" viewBox="0 0 62 42" {...rest}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-321.000000, -468.000000)"
            fill="#E4E9EF"
            fillRule="nonzero"
          >
            <g transform="translate(321.000000, 468.000000)">
              <path d="M51.8,18.1 C50.4,7.7 41.6,0 31,0 C21.9,0 14,5.7 11.1,14.3 C4.6,15.7 0,21.3 0,28 C0,35.3 5.7,41.4 13,42 L51,42 C51,42 51.1,42 51.1,42 C57.2,41.4 62,36.2 62,30 C62,24.1 57.6,19 51.8,18.1 Z M41.7,23.7 C41.5,23.9 41.3,24 41,24 C40.7,24 40.5,23.9 40.3,23.7 L32,15.4 L32,35 C32,35.6 31.6,36 31,36 C30.4,36 30,35.6 30,35 L30,15.4 L21.7,23.7 C21.3,24.1 20.7,24.1 20.3,23.7 C19.9,23.3 19.9,22.7 20.3,22.3 L30.3,12.3 C30.4,12.2 30.5,12.1 30.6,12.1 C30.8,12 31.1,12 31.4,12.1 C31.5,12.2 31.6,12.2 31.7,12.3 L41.7,22.3 C42.1,22.7 42.1,23.3 41.7,23.7 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
);

UploadCloud.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

UploadCloud.displayName = "UploadCloud";

export default UploadCloud;
