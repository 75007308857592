import React, { useState } from "react";
import styled from "styled-components";
import constants from "../../constants";
import PropTypes from "prop-types";

import { SIZE } from "./constants";

import { getInputLabelStyles, getInputStyles, getInputWrapperStyles } from "./styles";

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: ${props => props.mobileWidth || "350px"};
  width: ${props => props.mobileWidth || "350px"};
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    max-width: ${props => props.width || "390px"};
    width: ${props => props.width || "390px"};
  }
  ${getInputWrapperStyles};
`;

const Label = styled.label`
  ${getInputLabelStyles};
`;

const StyledInput = styled.input`
  ${getInputStyles};
`;

function Input(props) {
  const { labelAdditional, onBlur, error, value, icon, isBasic } = props;
  const [isFocus, setIsFocus] = useState(false);
  const isActiveLabel = isFocus || value;

  const hasError = error;
  const hasFocus = !hasError && isFocus;

  const handleBlur = event => {
    if (onBlur) {
      onBlur(event);
    }

    setIsFocus(false);
  };

  const handleOnFocus = () => {
    setIsFocus(true);
  };

  return (
    <Container>
      <Wrapper {...props} hasFocus={hasFocus} isBasic={isBasic}>
        <StyledInput data-naga-components="input" {...props} onFocus={handleOnFocus} onBlur={handleBlur} />
        <Label isActiveLabel={isActiveLabel} isBasic={isBasic}>
          {labelAdditional}
        </Label>
        {icon && icon}
      </Wrapper>
    </Container>
  );
}

Input.propTypes = {
  size: PropTypes.oneOf([SIZE.default, SIZE.compact, SIZE.large]),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  positive: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  icon: PropTypes.node,
  isBasic: PropTypes.bool,
};

export default Input;
