import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useDropzone } from "react-dropzone";

import { ParagraphMedium, ParagraphLarge } from "../typography";
import XIcon from "../../icons/X";
import XErrorIcon from "../../icons/XError";
import CheckPositiveIcon from "../../icons/CheckPositive";
import UploadCloudIcon from "../../icons/UploadCloud";
import CheckNeutralIcon from "../../icons/CheckNeutral";
import FileTextIcon from "../../icons/FileText";
import Spinner from "../spinner/Spinner";

function getBorderColor(props) {
  const { isDragActive, error } = props;

  if (isDragActive) {
    return css`
      border-color: ${(props) => props.theme.colors.accent};
    `;
  } else if (error) {
    return css`
      border-color: ${(props) => props.theme.colors.negative};
    `;
  }
  return css`
    border-color: ${(props) => props.theme.colors.inputBorder};
  `;
}

function getHoverStyles(props) {
  const { disabled } = props;

  if (!disabled) {
    return css`
      border-color: ${(props) => props.theme.colors.accent};
      background-color: ${(props) => props.theme.colors.backgroundTertiary};
    `;
  }
}

const StyledFileUploader = styled.div`
  height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: dashed;
  outline: 0;
  position: relative;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: ${(props) => props.theme.borders.radius200};
  background-color: ${(props) => props.theme.colors.backgroundSecondary};
  ${getBorderColor};
  &:hover {
    ${getHoverStyles};
  }
  ${(props) => props.theme.animations.easeOutCurve};
  svg {
    color: ${(props) => props.theme.colors.contentPrimary};
  }
`;

const Thumb = styled.img`
  object-fit: cover;
  height: 100%;
  max-width: 100%;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  display: flex;
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  display: flex;
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.inputBorder};
  background-color: ${(props) => props.theme.colors.background};
`;

const Subtitle = styled(ParagraphMedium)`
  color: ${(props) => props.theme.colors.contentSecondary};
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledUploadCloudIcon = styled(UploadCloudIcon)`
  margin-bottom: 1rem;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => `${props.theme.colors.backgroundSecondary}B2`};
`;

const ACCEPTES_TYPES = {
  pdf: "application/pdf",
};

function ImageUploader(props) {
  const { title, subtitle, error, positive, isLoading, isUploaded } = props;
  const [files, setFiles] = React.useState([]);

  function onDrop(acceptedFiles) {
    setFiles(
      acceptedFiles.map((file) => {
        return {
          ...file,
          preview: URL.createObjectURL(file),
        };
      })
    );
    props.onDrop(acceptedFiles);
  }

  const disabled = props.disabled || isLoading || isUploaded;
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
  } = useDropzone({
    accept: "application/pdf,image/*",
    onDrop,
    disabled,
  });

  React.useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  function handleClose(event) {
    setFiles([]);
    event.stopPropagation();
    if (typeof props.onClose === "function") {
      props.onClose();
    }
  }

  function renderXIcon() {
    if (files.length && !isLoading) {
      return (
        <CloseWrapper onClick={handleClose}>
          <XIcon size={20} />
        </CloseWrapper>
      );
    } else if (error) {
      return (
        <IconWrapper>
          <XErrorIcon size={20} />
        </IconWrapper>
      );
    }
  }

  function renderContent() {
    if (
      files.length &&
      acceptedFiles.length &&
      acceptedFiles[0].type === ACCEPTES_TYPES.pdf &&
      isLoading
    ) {
      return (
        <Overlay>
          <Spinner size={"86px"} />
        </Overlay>
      );
    } else if (files.length && isLoading) {
      return (
        <>
          <Thumb src={files[0].preview} />
          <Overlay>
            <Spinner size={"86px"} />
          </Overlay>
        </>
      );
    } else if (
      files.length &&
      acceptedFiles.length &&
      acceptedFiles[0].type === ACCEPTES_TYPES.pdf
    ) {
      return <FileTextIcon size={86} />;
    } else if (files.length) {
      return <Thumb src={files[0].preview} />;
    }
    return (
      <Center>
        {!isLoading && !positive && isUploaded && (
          <CheckNeutralIcon size={86} />
        )}
        {!isLoading && !positive && !isUploaded && <StyledUploadCloudIcon />}
        {isLoading && <Spinner size={"86px"} />}
        {positive && !isLoading && <CheckPositiveIcon size={86} />}
        {!isLoading && !positive && !isUploaded && (
          <>
            <ParagraphLarge>{title}</ParagraphLarge>
            <Subtitle>{subtitle}</Subtitle>
          </>
        )}
      </Center>
    );
  }

  return (
    <StyledFileUploader
      data-naga-components="image-uploader"
      {...getRootProps()}
      isDragActive={isDragActive}
      disabled={disabled}
      error={error}
      positive={positive}
    >
      <input {...getInputProps()} />
      {renderXIcon()}
      {renderContent()}
    </StyledFileUploader>
  );
}

ImageUploader.propTypes = {
  onDrop: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  error: PropTypes.bool,
  positive: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUploaded: PropTypes.bool,
};

ImageUploader.defaultProps = {
  disabled: false,
  multiple: false,
  title: "Select Images to Upload",
  subtitle: "or drag and drop, copy and paste images",
  error: false,
  positive: false,
  isLoading: false,
  isUploaded: false,
};

export default ImageUploader;
