import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";

import themes from "./themes";
import GlobalStyle from "./GlobalStyle";
import constants from "./constants";

import { Select } from "./components/select";

import { FormControl } from "./components/form-control";
import { ImageUploader } from "./components/image-uploader";
import { DatePicker } from "./components/date-picker";

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    grid-template-columns: 1fr 1fr;
    width: calc(50% - 0.5rem);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    grid-template-columns: 1fr 1fr;
  }
`;

function StatefulVerifyIdentity(props) {
  const {
    darkMode,
    documentTypeLabel,
    poiDocuments,
    poiDocumentFrontOnDrop,
    poiDocumentBackOnDrop,
    expirationDateLabel,
    expirationDateModifiers,
    poiFrontTitle,
    poiBackTitle,
    poiFrontSubtitle,
    poiBackSubtitle,
  } = props;
  const theme = darkMode ? themes.dark : themes.light;
  const [poiDocument, setPoiDocument] = React.useState(poiDocuments[0]);
  const [expirationDate, setExpirationDate] = React.useState("");

  function expirationDateOnChange(date) {
    setExpirationDate(date);
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Inputs>
          <div>
            <FormControl label={documentTypeLabel}>
              <Select
                value={poiDocument}
                options={poiDocuments}
                onChange={setPoiDocument}
              />
            </FormControl>
          </div>
          <div>
            <FormControl label={expirationDateLabel}>
              <DatePicker
                value={expirationDate}
                onChange={expirationDateOnChange}
                modifiers={expirationDateModifiers}
              />
            </FormControl>
          </div>
        </Inputs>
        <Grid>
          <ImageUploader
            onDrop={poiDocumentFrontOnDrop}
            title={poiFrontTitle}
            subtitle={poiFrontSubtitle}
          />
          <ImageUploader
            onDrop={poiDocumentBackOnDrop}
            title={poiBackTitle}
            subtitle={poiBackSubtitle}
          />
        </Grid>
      </ThemeProvider>
      <GlobalStyle />
    </>
  );
}

StatefulVerifyIdentity.propTypes = {
  darkMode: PropTypes.bool,
  documentTypeLabel: PropTypes.string,
  poiDocuments: PropTypes.array,
  poiDocumentFrontOnDrop: PropTypes.func,
  poiDocumentBackOnDrop: PropTypes.func,
  expirationDateLabel: PropTypes.string,
  expirationDateModifiers: PropTypes.object,
  poiFrontTitle: PropTypes.string,
  poiBackTitle: PropTypes.string,
  poiFrontSubtitle: PropTypes.string,
  poiBackSubtitle: PropTypes.string,
};

StatefulVerifyIdentity.defaultProps = {
  darkMode: false,
  documentTypeLabel: "Document type",
  poiDocuments: [],
  expirationDateLabel: "Expiration date",
};

export default StatefulVerifyIdentity;
