import React from "react";
import styled from "styled-components";

const StyledDebug = styled.div`
  padding: 1rem;
  color: ${(props) => props.theme.colors.contentPrimary};
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  ${(props) => props.theme.typography.font300};
`;

function Debug(props) {
  const { formik } = props;

  return (
    <StyledDebug>
      <pre>{JSON.stringify(formik.values, null, 1)}</pre>
    </StyledDebug>
  );
}

export default Debug;
