import React from "react";
import styled, { css, DefaultTheme } from "styled-components";

import { formControlProps } from "../../types";

const StyledFormControl = styled.div<{ mb: string }>`
  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
`;

const Label = styled.label<{ disabled: boolean }>`
  display: block;
  margin: 8px 0;
  color: ${(props) =>
    props.disabled
      ? props.theme.naga.colors.foregroundAlt
      : props.theme.naga.colors.foreground};
  font-size: ${(props) => props.theme.naga.typography.size.paragraphSmall};
`;

function getColor(props: {
  error: boolean;
  positive: boolean;
  theme: DefaultTheme;
}) {
  const { error, positive } = props;

  if (error) {
    return props.theme.naga.colors.negative;
  } else if (positive) {
    return props.theme.naga.colors.positive;
  }
  return props.theme.naga.colors.foregroundAlt;
}

const Caption = styled.div<{ error: boolean; positive: boolean }>`
  margin: 8px 0;
  color: ${getColor};
  font-size: ${(props) => props.theme.naga.typography.size.paragraphXSmall};
`;

function FormControl(props: formControlProps): React.ReactElement {
  const {
    label = "",
    htmlFor = "",
    caption = "",
    disabled = false,
    error = false,
    positive = false,
    mb,
    children,
  } = props;

  return (
    <StyledFormControl mb={mb}>
      {label && (
        <Label disabled={disabled} htmlFor={htmlFor}>
          {label}
        </Label>
      )}
      {React.cloneElement(children, { disabled, error, positive })}
      {caption && (
        <Caption error={error} positive={positive}>
          {caption}
        </Caption>
      )}
    </StyledFormControl>
  );
}

export default FormControl;
