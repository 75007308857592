import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";

import themes from "./themes";
import GlobalStyle from "./GlobalStyle";
import constants from "./constants";

import { Select } from "./components/select";
import { FormControl } from "./components/form-control";
import { ImageUploader } from "./components/image-uploader";
import { DatePicker } from "./components/date-picker";
import Tooltip from "./Tooltip";

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    grid-template-columns: 1fr 1fr;
    width: calc(50% - 0.5rem);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    grid-template-columns: 1fr 1fr;
  }
`;

function VerifyIdentity(props) {
  const {
    darkMode,
    documentTypeLabel,
    poiDocument,
    poiDocuments,
    poiDocumentOnChange,
    poiDocumentFrontOnDrop,
    poiDocumentBackOnDrop,
    expirationDateLabel,
    expirationDate,
    expirationDateOnChange,
    expirationDateModifiers,
    expirationDateInitialMonth,
    expirationDateInfo,
    poiFrontTitle,
    poiBackTitle,
    poiFrontSubtitle,
    poiBackSubtitle,
    poiFrontCaption,
    poiFrontPositive,
    poiFrontError,
    poiFrontIsLoading,
    poiFrontIsUploaded,
    poiFrontImageUploaderDisabled,
    poiBackCaption,
    poiBackPositive,
    poiBackError,
    poiBackIsLoading,
    poiBackIsUploaded,
    poiBackImageUploaderDisabled,
    documentTypeDisabled,
    expirationDateDisabled,
    expirationDateError,
    poiFrontOnClose,
    poiBackOnClose,
    hidden,
    title,
    info,
  } = props;
  const theme = darkMode ? themes.dark : themes.light;
  const hideFront =
    (Array.isArray(hidden) && hidden.find((item) => item === "front")) || false;
  const hideBack =
    (Array.isArray(hidden) && hidden.find((item) => item === "back")) || false;

  if (!Array.isArray(hidden)) {
    console.warn(
      `[document-verificatin] "hidden" prop should be an array, found ${typeof hidden}`
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Tooltip label={title} info={info} />
        <Inputs>
          <div>
            <FormControl
              label={documentTypeLabel}
              disabled={documentTypeDisabled}
            >
              <Select
                value={poiDocument}
                options={poiDocuments}
                onChange={poiDocumentOnChange}
              />
            </FormControl>
          </div>
          <div>
            <FormControl
              label={expirationDateLabel}
              disabled={expirationDateDisabled}
              error={expirationDateError}
            >
              <DatePicker
                value={expirationDate}
                onChange={expirationDateOnChange}
                modifiers={expirationDateModifiers}
                initialMonth={expirationDateInitialMonth}
                info={expirationDateInfo}
              />
            </FormControl>
          </div>
        </Inputs>
        <Grid>
          {hideFront ? null : (
            <div>
              <FormControl
                caption={poiFrontCaption}
                positive={poiFrontPositive}
                error={poiFrontError}
                disabled={poiFrontImageUploaderDisabled}
              >
                <ImageUploader
                  onDrop={poiDocumentFrontOnDrop}
                  title={poiFrontTitle}
                  subtitle={poiFrontSubtitle}
                  onClose={poiFrontOnClose}
                  isLoading={poiFrontIsLoading}
                  isUploaded={poiFrontIsUploaded}
                />
              </FormControl>
            </div>
          )}
          {hideBack ? null : (
            <div>
              <FormControl
                caption={poiBackCaption}
                positive={poiBackPositive}
                error={poiBackError}
                disabled={poiBackImageUploaderDisabled}
              >
                <ImageUploader
                  onDrop={poiDocumentBackOnDrop}
                  title={poiBackTitle}
                  subtitle={poiBackSubtitle}
                  onClose={poiBackOnClose}
                  isLoading={poiBackIsLoading}
                  isUploaded={poiBackIsUploaded}
                />
              </FormControl>
            </div>
          )}
        </Grid>
      </ThemeProvider>
      <GlobalStyle />
    </>
  );
}

VerifyIdentity.propTypes = {
  darkMode: PropTypes.bool,
  documentTypeLabel: PropTypes.string,
  poiDocument: PropTypes.object,
  poiDocuments: PropTypes.array,
  poiDocumentOnChange: PropTypes.func,
  poiDocumentFrontOnDrop: PropTypes.func,
  poiDocumentBackOnDrop: PropTypes.func,
  expirationDateLabel: PropTypes.string,
  expirationDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  expirationDateOnChange: PropTypes.func,
  expirationDateModifiers: PropTypes.object,
  expirationDateInitialMonth: PropTypes.instanceOf(Date),
  expirationDateInfo: PropTypes.string,
  poiFrontTitle: PropTypes.string,
  poiBackTitle: PropTypes.string,
  poiFrontSubtitle: PropTypes.string,
  poiBackSubtitle: PropTypes.string,
  poiFrontCaption: PropTypes.string,
  poiFrontPositive: PropTypes.bool,
  poiFrontError: PropTypes.bool,
  poiFrontIsLoading: PropTypes.bool,
  poiFrontIsUploaded: PropTypes.bool,
  poiFrontImageUploaderDisabled: PropTypes.bool,
  poiBackCaption: PropTypes.string,
  poiBackPositive: PropTypes.bool,
  poiBackError: PropTypes.bool,
  poiBackIsLoading: PropTypes.bool,
  poiBackIsUploaded: PropTypes.bool,
  poiBackImageUploaderDisabled: PropTypes.bool,
  documentTypeDisabled: PropTypes.bool,
  expirationDateDisabled: PropTypes.bool,
  expirationDateError: PropTypes.bool,
  poiFrontOnClose: PropTypes.func,
  poiBackOnClose: PropTypes.func,
  hidden: PropTypes.array,
  title: PropTypes.string,
};

VerifyIdentity.defaultProps = {
  darkMode: false,
  documentTypeLabel: "Document type",
  poiDocument: { value: "", label: "" },
  poiDocuments: [],
  expirationDateLabel: "Expiration date",
  expirationDate: "",
  poiFrontCaption: "",
  poiFrontPositive: false,
  poiFrontError: false,
  poiFrontIsLoading: false,
  poiFrontIsUploaded: false,
  poiFrontImageUploaderDisabled: false,
  poiBackCaption: "",
  poiBackPositive: false,
  poiBackError: false,
  poiBackIsLoading: false,
  poiBackIsUploaded: false,
  poiBackImageUploaderDisabled: false,
  documentTypeDisabled: false,
  expirationDateDisabled: false,
  expirationDateError: false,
  hidden: "back",
  title: "1. Verify Identity",
  info: <div>Content</div>,
};

export default VerifyIdentity;
