import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const XError = forwardRef(
  ({ color = "currentColor", size = 24, ...rest }, ref) => {
    return (
      <svg ref={ref} width={size} height={size} viewBox="0 0 20 20" {...rest}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-604.000000, -404.000000)">
            <g transform="translate(604.000000, 404.000000)">
              <circle
                stroke="#EE3142"
                fill="#EE3142"
                cx="10"
                cy="10"
                r="9.5"
              ></circle>
              <path
                d="M14.7857143,5.21428571 C14.5,4.92857143 14.0714286,4.92857143 13.7857143,5.21428571 L10,9 L6.21428571,5.21428571 C5.92857143,4.92857143 5.5,4.92857143 5.21428571,5.21428571 C4.92857143,5.5 4.92857143,5.92857143 5.21428571,6.21428571 L9,10 L5.21428571,13.7857143 C4.92857143,14.0714286 4.92857143,14.5 5.21428571,14.7857143 C5.35714286,14.9285714 5.5,15 5.71428571,15 C5.92857143,15 6.07142857,14.9285714 6.21428571,14.7857143 L10,11 L13.7857143,14.7857143 C13.9285714,14.9285714 14.1428571,15 14.2857143,15 C14.4285714,15 14.6428571,14.9285714 14.7857143,14.7857143 C15.0714286,14.5 15.0714286,14.0714286 14.7857143,13.7857143 L11,10 L14.7857143,6.21428571 C15.0714286,5.92857143 15.0714286,5.5 14.7857143,5.21428571 Z"
                fill="#FFFFFF"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
);

XError.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

XError.displayName = "XError";

export default XError;
