/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { components } from "react-select";
import { useTheme } from "styled-components";

import constants from "../../constants";

import { Select } from "../select";
import { Input } from "../input";

const styles = (theme, dropdownWidth) => ({
  container: provided => ({
    ...provided,
    width: "100%",
    height: "100%",
    outline: 0,
  }),
  menu: provided => ({
    ...provided,
    width: `${dropdownWidth ? `${dropdownWidth}px` : "100%"}`,
    backgroundColor: theme.colors.menuFill,
    boxShadow: `0px 4px 15px 0px ${theme.colors.boxShaddow}`,
    marginBottom: 0,
    borderRadius: 8,
    zIndex: 9999,
  }),
});

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 350px;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    width: 390px;
  }
`;

const StyledImageDiv = styled.div`
  width: 20px;
  margin-right: 8px;
  img {
    max-width: 100%;
  }
`;

const StyledOption = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
    line-height: 20px;
    padding-top: 2px;
    flex: 1;
  }
`;

const StyledInputWrapper = styled.div`
  flex: 1;
`;

const Option = props => {
  const { data, label } = props;

  return (
    <components.Option {...props}>
      <StyledOption>
        {data.icon && (
          <StyledImageDiv>
            <img src={data.icon} alt="" />
          </StyledImageDiv>
        )}
        {label && <span>{label}</span>}
      </StyledOption>
    </components.Option>
  );
};

const SingleValue = props => {
  const { data } = props;

  return (
    <components.SingleValue {...props}>
      <StyledOption>
        {data.icon && (
          <StyledImageDiv>
            <img src={data.icon} alt={data.phone_code} />
          </StyledImageDiv>
        )}
        {data.phone_code && <span>{data.phone_code}</span>}
      </StyledOption>
    </components.SingleValue>
  );
};

const getCountryByCode = (countries, code) => {
  if (!countries) {
    return null;
  }

  return countries.find(country => country.code === code);
};

const handleFilterOption = (option, filter) => {
  const re = new RegExp(escapeRegExp(filter), "gi");

  return (option.data.phone_code && option.data.phone_code.match(re)) || (option.data.name && option.data.name.match(re));
};

const escapeRegExp = str => {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
};

function PhoneInput(props) {
  const { onCountryChange, value, options, name, onChange, onBlur, error, disabled, additionalInputLabel, additionalSelectLabel } = props;
  const selectedCountryCode = getCountryByCode(options, value ? value.country_code : null);
  const [dropdownWidth, setDropdownWidth] = useState(null);
  const wrapperRef = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    setDropdownWidth(wrapperRef.current?.clientWidth);
  }, [window.innerWidth]);

  return (
    <Wrapper ref={wrapperRef} data-naga-components="phone-input">
      <Select
        value={selectedCountryCode}
        options={options}
        onChange={onCountryChange}
        onBlur={onBlur}
        filterOption={handleFilterOption}
        components={{ SingleValue, Option }}
        getOptionValue={option => option.phone_code}
        getOptionLabel={option => `${option.name} ${option.phone_code}`}
        styles={styles(theme, dropdownWidth)}
        disabled={disabled}
        width="173px"
        mobileWidth="144px"
        name="country_phone_code"
        labelAdditional={additionalSelectLabel}
      />
      <StyledInputWrapper>
        <Input
          labelAdditional={additionalInputLabel}
          value={value.phone}
          type="tel"
          width="208px"
          mobileWidth="198px"
          name={name}
          onChange={onChange}
          error={error}
          onBlur={onBlur}
          disabled={disabled}
        />
      </StyledInputWrapper>
    </Wrapper>
  );
}

PhoneInput.propTypes = {
  country: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func,
  onCountryChange: PropTypes.func,
};

PhoneInput.defaultProps = {
  options: [],
  value: "",
};

export default PhoneInput;
