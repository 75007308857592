import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const Search = forwardRef(({ color = "currentColor", size = 24, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 18C14.6421 18 18 14.6421 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18Z"
        stroke="#7F93BC"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M21.0008 21L15.8008 15.8" stroke="#7F93BC" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
});

Search.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Search.displayName = "Search";

export default Search;
