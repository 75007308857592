import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { KIND, SHAPE } from "../button";
import { DIRECTION } from "./constants";
import constants from "../../constants";

function getDirectionStyles(props) {
  const { direction } = props;

  switch (direction) {
    default:
    case DIRECTION.column:
      return css`
        color: ${props.theme.colors.buttonPrimaryText};
        align-items: center;
        margin-top: 24px;
        padding: 0 16px;
        @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
          padding: 0 24px;
        }
      `;
    case DIRECTION.row:
      return css`
        background-color: ${props => props.theme.colors.buttonSecondaryFill};
        width: fit-content;
      `;
  }
}

const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  border-radius: 8px;
  max-height: 410px;
  overflow-y: auto;
  scrollbar-color: ${props => `${props.theme.colors.accent} rgba(152, 188, 255, 0.5)`};
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(152, 188, 255, 0.5);
    border-radius: 8px;
    margin: 50px 0;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.accent};
    border-radius: 8px;
  }
  ${getDirectionStyles};
`;

function ButtonGroup(props) {
  const { children, disabled, selected, onClick, kind, shape, overrideElementNumber } = props;
  const childArray = React.Children.toArray(children);
  const childArrayLength = overrideElementNumber || childArray.length;

  function isSelected(selected, index) {
    if (!Array.isArray(selected) && typeof selected !== "number") {
      return false;
    }
    if (Array.isArray(selected)) {
      return selected.includes(index);
    }
    return selected === index;
  }

  function getShapePillStyles(index, length, isSelected) {
    if (isSelected) {
      return {
        borderRadius: 8,
      };
    }
  }

  if (constants.IS_DEV && childArrayLength < 2) {
    console.warn(`[button-group] Expected more than 1 child, found ${childArray.length}`);
  }

  return (
    <StyledButtonGroup direction={childArrayLength > 2 ? DIRECTION.column : DIRECTION.row} data-naga-components="button-group">
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) {
          if (constants.IS_DEV) {
            console.error(`[button-group] Expected HTML child element, got ${typeof child}`);
          }
          return null;
        }
        return React.cloneElement(child, {
          ...child.props,
          kind,
          disabled: disabled || child.props.disabled,
          isSelected: isSelected(selected, index),
          onClick: event => {
            if (disabled) {
              return;
            }
            if (child.props.onClick) {
              child.props.onClick(event);
            }
            if (onClick) {
              onClick(event, index);
            }
          },
          style: getShapePillStyles(index, childArrayLength, isSelected(selected, index)),
        });
      })}
    </StyledButtonGroup>
  );
}

ButtonGroup.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.number),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  kind: PropTypes.oneOf([KIND.primary, KIND.secondary, KIND.tertiary]),
  shape: PropTypes.oneOf([SHAPE.default, SHAPE.pill]),
  direction: PropTypes.oneOf([DIRECTION.row, DIRECTION.column]),
  overrideElementNumber: PropTypes.number,
};

ButtonGroup.defaultProps = {
  selected: [],
  disabled: false,
  kind: KIND.secondary,
  shape: SHAPE.default,
  direction: DIRECTION.row,
  overrideElementNumber: 0,
};

export default ButtonGroup;
