import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import ChevronRight from "../../icons/ChevronRight";

const Label = styled.label`
  text-align: center;
  display: block;
  margin-bottom: 48px;
  margin-top: 10px;
  white-space: pre-wrap;
  width: 100%;
  color: ${props => props.theme.colors.activeText};
  ${props => props.theme.typography.font400}
`;

const StyledHyperlink = styled.div`
  display: flex;
  width: 340px;
  justify-content: space-between;
  padding: 16px;
  background: ${props => props.theme.colors.backgroundMenu};
  border-radius: 8px;
  cursor: pointer;
  color: ${props => props.theme.colors.accent};
  ${props => props.theme.typography.font300}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function Hyperlink(props) {
  const { label, labelUrl, labelAdditional } = props;

  const handleOnClick = () => {
    window.open(labelUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <Wrapper>
      <Label>{labelAdditional}</Label>
      <StyledHyperlink onClick={handleOnClick}>
        <p>{label}</p>
        <ChevronRight color="#3179FF" size={18} />
      </StyledHyperlink>
    </Wrapper>
  );
}

Hyperlink.propTypes = {
  label: PropTypes.string,
  labelUrl: PropTypes.string,
  labelAdditional: PropTypes.string,
};

Hyperlink.defaultProps = {
  label: "",
  labelUrl: "",
  labelAdditional: "",
};

export default Hyperlink;
