import React from "react";
import styled from "styled-components";

const StyledModalHeader = styled.div`
  padding-top: ${(props) => props.theme.naga.spacing.padding.medium};
  padding-right: ${(props) => props.theme.naga.spacing.padding.large};
  padding-bottom: ${(props) => props.theme.naga.spacing.padding.medium};
  padding-left: ${(props) => props.theme.naga.spacing.padding.large};
  color: ${(props) => props.theme.naga.colors.contentPrimary};
`;

type props = {
  children?: React.ReactNode;
};

function ModalHeader(props: props): React.ReactElement {
  const { children } = props;

  return <StyledModalHeader>{children}</StyledModalHeader>;
}

export default ModalHeader;
