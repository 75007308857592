import React from "react";
import styled, { css, DefaultTheme } from "styled-components";

import { notificationKind, notificationProps } from "../../types";

function getBackgroundColorStyles(props: {
  kind: notificationKind;
  theme: DefaultTheme;
}) {
  const { kind } = props;

  switch (kind) {
    default:
    case "info":
      return props.theme.naga.colors.notificationInfoBackground;
    case "positive":
      return props.theme.naga.colors.notificationPositiveBackground;
    case "warning":
      return props.theme.naga.colors.notificationWarningBackground;
    case "negative":
      return props.theme.naga.colors.notificationNegativeBackground;
  }
}

function getColorStyles(props: {
  kind: notificationKind;
  theme: DefaultTheme;
}) {
  const { kind } = props;

  switch (kind) {
    default:
    case "info":
      return props.theme.naga.colors.notificationInfoText;
    case "positive":
      return props.theme.naga.colors.notificationPositiveText;
    case "warning":
      return props.theme.naga.colors.notificationWarningText;
    case "negative":
      return props.theme.naga.colors.notificationNegativeText;
  }
}

const StyledNofitication = styled.div<{
  kind: notificationKind;
  shouldFitContainer: boolean;
  mb: string;
}>`
  padding: ${(props) => props.theme.naga.spacing.padding.medium};
  width: ${(props) => (props.shouldFitContainer ? "100%" : "18em")};
  background-color: ${getBackgroundColorStyles};
  color: ${getColorStyles};
  font-size: ${(props) => props.theme.naga.typography.size.paragraphSmall};
  border-radius: ${(props) => props.theme.naga.spacing.borderRadius.default};
  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
`;

function Notification(props: notificationProps): React.ReactElement {
  const { kind = "info", shouldFitContainer = false, mb, children } = props;

  return (
    <StyledNofitication
      kind={kind}
      shouldFitContainer={shouldFitContainer}
      mb={mb}
    >
      {children}
    </StyledNofitication>
  );
}

export default Notification;
