import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";

import themes from "./themes";
import GlobalStyle from "./GlobalStyle";
import constants from "./constants";

import { Select } from "./components/select";

import { FormControl } from "./components/form-control";
import { ImageUploader } from "./components/image-uploader";
import { DatePicker } from "./components/date-picker";

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    grid-template-columns: 1fr 1fr;
    width: calc(50% - 0.5rem);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    grid-template-columns: 1fr 1fr;
  }
`;

function StatefulVerifyResidence(props) {
  const {
    darkMode,
    documentTypeLabel,
    porDocuments,
    porDocumentOnDrop,
    issuingDateLabel,
    issuingDateModifiers,
    porTitle,
    porSubtitle,
  } = props;
  const theme = darkMode ? themes.dark : themes.light;
  const [porDocument, setPorDocument] = React.useState(porDocuments[0]);
  const [issuingDate, setIssuingDate] = React.useState("");

  function issuingDateOnChange(date) {
    setIssuingDate(date);
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Inputs>
          <div>
            <FormControl label={documentTypeLabel}>
              <Select
                value={porDocument}
                options={porDocuments}
                onChange={setPorDocument}
              />
            </FormControl>
          </div>
          <div>
            <FormControl label={issuingDateLabel}>
              <DatePicker
                value={issuingDate}
                onChange={issuingDateOnChange}
                modifiers={issuingDateModifiers}
              />
            </FormControl>
          </div>
        </Inputs>
        <Grid>
          <ImageUploader
            onDrop={porDocumentOnDrop}
            title={porTitle}
            subtitle={porSubtitle}
          />
        </Grid>
      </ThemeProvider>
      <GlobalStyle />
    </>
  );
}

StatefulVerifyResidence.propTypes = {
  darkMode: PropTypes.bool,
  documentTypeLabel: PropTypes.string,
  porDocuments: PropTypes.array,
  porDocumentOnDrop: PropTypes.func,
  issuingDateLabel: PropTypes.string,
  issuingDateModifiers: PropTypes.object,
  porTitle: PropTypes.string,
  porSubtitle: PropTypes.string,
};

StatefulVerifyResidence.defaultProps = {
  darkMode: false,
  documentTypeLabel: "Document type",
  porDocuments: [],
  issuingDateLabel: "Issuing Date",
};

export default StatefulVerifyResidence;
