import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const ChevronDown = forwardRef(
  ({ color = "currentColor", size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...rest}>
        <path d={`M${size}  1L1 ${size}`} stroke="#7F93BC" strokeLinecap="round" strokeLinejoin="round"/>
        <path d={`M1 1L${size} ${size}`} stroke="#7F93BC" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
  }
);

ChevronDown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ChevronDown.displayName = "ChevronDown";

export default ChevronDown;
