/* eslint-disable no-inner-declarations */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { FormControl } from "./components/form-control";
import { Input } from "./components/input";
import { Select } from "./components/select";
import { RadioGroup, Radio, ALIGN } from "./components/radio";
import { ButtonGroup } from "./components/button-group";
import { Button, KIND } from "./components/button";
import { Checkbox } from "./components/checkbox";
import { MultiCheckbox } from "./components/multi-checkbox";
import { Birthday } from "./components/birthday";
import { PhoneInput } from "./components/phone-input";
import Hyperlink from "./components/hyperlink/Hyperlink";
import Tooltip from "./Tooltip";
import SearchIcon from "./icons/Search";

import constants from "./constants";
import utils from "./utils";

const INITIAL_FILTER_STATE = {
  value: null,
  options: null,
};

const MultiCheckboxWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  margin-top: 24px;
  justify-content: center;
  grid-template-columns: repeat(1, auto);
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    grid-template-columns: repeat(2, auto);
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: relative;
  right: 20px;
`;

const TooltipWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  width: 100%;
  color: ${props => props.theme.colors.activeText};
  ${props => props.theme.typography.font550}
  padding: 0 56px;
  @media (min-width: ${constants.BREAKPOINTS.MEDIUM_DEVICES}) {
    padding: 0;
  }
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: ${props => props.templateAreas || "initial"};
`;

const GridItem = styled.div`
  grid-area: ${props => props.area};
`;

function Step(props) {
  const {
    formik,
    fields,
    stepNumber,
    templateAreas,
    className,
    disabledFields,
    hiddenFields,
    tooltipTitle,
    options,
    labels,
    isLoading,
    handleLabel,
    handleOptionLabel,
    labelsAdditional,
    handleSubmit,
    isSingleChoice,
    children,
  } = props;

  const [filter, setFilter] = useState(INITIAL_FILTER_STATE);

  useEffect(() => {
    setFilter(INITIAL_FILTER_STATE);
  }, [stepNumber]);

  function handleChange(field, e) {
    formik.setFieldValue(field, e);
  }

  function filterOnChange(e, allOptions) {
    const value = e.target.value || null;
    setFilter({
      value,
      options: allOptions.filter(option => option.text.toLowerCase().includes((value || "").toLowerCase())),
    });
  }

  function handleBlur(field) {
    formik.setFieldTouched(field, true);
  }

  function handleHiddenRules(item) {
    if (item.rules && !utils.isEmpty(item.rules.hidden)) {
      const operandA = item.rules.hidden.operandA;
      const valueA = (formik.values[operandA] && formik.values[operandA].value) || formik.values[operandA];
      const valueB = item.rules.hidden.operandB;
      const operation = item.rules.hidden.operation;

      switch (operation) {
        case constants.OPERATIONS.equal:
          return valueA === valueB;
        case constants.OPERATIONS.notEqual:
          return valueA !== valueB;
        case constants.OPERATIONS.isOneOf:
          return valueB ? valueB.split(",").indexOf(valueA) > -1 : true;
        case constants.OPERATIONS.isNotOneOf:
          return valueB ? valueB.split(",").indexOf(valueA) === -1 : true;
        default:
          return false;
      }
    }

    return false;
  }

  function handleDisabled(item) {
    return disabledFields.indexOf(item.name) > -1;
  }

  function handleHidden(item) {
    return hiddenFields.indexOf(item.name) > -1;
  }

  function handleLabelText(item) {
    if (labels[item.name]) {
      return labels[item.name];
    } else if (typeof handleLabel === "function") {
      return handleLabel(item);
    }
    return item.label;
  }

  function handleOptionLabelText(item) {
    if (typeof handleOptionLabel === "function") {
      return handleOptionLabel(item);
    }
    return item.label;
  }

  return (
    <Fields templateAreas={templateAreas} className={className}>
      {fields.map(item => {
        const { name } = item;
        const area = templateAreas ? name : "initial";
        const hidden = item.hidden === "true" || item.active === "false" || handleHiddenRules(item) || handleHidden(item);
        const disabled = handleDisabled(item);
        const labelText = handleLabelText(item);
        const label = item.info ? (
          <TooltipWrapper>
            <Tooltip info={item.info} label={labelText} title={tooltipTitle} htmlFor={name} />
          </TooltipWrapper>
        ) : (
          labelText
        );
        if (hidden) {
          return null;
        }

        if (
          item.type === constants.INPUT_TYPES.text ||
          item.type === constants.INPUT_TYPES.password ||
          item.type === constants.INPUT_TYPES.number ||
          item.type === constants.INPUT_TYPES.email ||
          item.type === constants.INPUT_TYPES.tel
        ) {
          const labelAdditional = labelsAdditional[name] || null;

          return item.id === constants.INPUT_IDS.address ? (
            <GridItem key={name} area={area} className={item.gridItemClassName}>
              {labelAdditional}
            </GridItem>
          ) : (
            <GridItem key={name} area={area} className={item.gridItemClassName}>
              <FormControl
                label={label}
                caption={formik.touched[name] && formik.errors[name]}
                error={Boolean(formik.errors[name] && formik.touched[name])}
                disabled={disabled || formik.isSubmitting}
                htmlFor={name}
              >
                <Input labelAdditional={labelAdditional} type={item.type} name={name} value={formik.values[name]} onChange={formik.handleChange} onBlur={formik.handleBlur} id={name} />
              </FormControl>
            </GridItem>
          );
        } else if (item.type === constants.INPUT_TYPES.select) {
          const caption =
            (formik.touched[name] && formik.touched[name].value && formik.errors[name] && formik.errors[name].value) ||
            (formik.touched[name] && formik.touched[name].label && formik.errors[name] && formik.errors[name].label);
          const error =
            (formik.touched[name] && formik.touched[name].value && formik.errors[name] && formik.errors[name].value) ||
            (formik.touched[name] && formik.touched[name].label && formik.errors[name] && formik.errors[name].label);
          const fieldOptions = options[name] || item.options;
          const loading = isLoading[name] || false;
          const labelAdditional = labelsAdditional[name] || null;
          const fieldOptionsLabels = fieldOptions.map(item => {
            return {
              ...item,
              label: handleOptionLabelText(item),
            };
          });
          return (
            <GridItem key={name} area={area} className={item.gridItemClassName}>
              <FormControl label={label} caption={caption} error={Boolean(error)} disabled={disabled || formik.isSubmitting} htmlFor={name}>
                <Select
                  onChange={e => handleChange(name, e)}
                  onBlur={() => handleBlur(name)}
                  labelAdditional={labelAdditional}
                  value={formik.values[name]}
                  error={error}
                  options={fieldOptionsLabels}
                  isLoading={loading}
                  inputId={name}
                />
              </FormControl>
            </GridItem>
          );
        } else if (item.type === constants.INPUT_TYPES.hyperlink) {
          const labelAdditional = labelsAdditional[name] || null;

          return (
            <GridItem key={name} area={area} className={item.gridItemClassName}>
              <Hyperlink labelAdditional={labelAdditional} label={label} labelUrl={item.label_url} />
            </GridItem>
          );
        } else if (item.type === constants.INPUT_TYPES.radio) {
          const fieldOptions = options[name] || item.options;

          return (
            <GridItem key={name} area={area} className={item.gridItemClassName}>
              <FormControl label={label} caption={formik.touched[name] && formik.errors[name]} error={Boolean(formik.errors[name] && formik.touched[name])} disabled={disabled} htmlFor={name}>
                <RadioGroup name={name} value={formik.values[name]} onChange={formik.handleChange} onBlur={formik.handleBlur} align={ALIGN.horizontal}>
                  {fieldOptions.map(item => {
                    return (
                      <Radio key={item.value} value={item.value}>
                        {handleOptionLabelText(item)}
                      </Radio>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </GridItem>
          );
        } else if (item.type === constants.INPUT_TYPES.buttonGroup) {
          const allOptions = options[name] || item.options;
          const kind = allOptions.length > 2 ? KIND.tertiary : KIND.secondary;
          const fieldOptions = (filter.value && filter.options) || allOptions;

          const selected = fieldOptions.findIndex(item => item.value === formik.values[name]);
          const handleClick = (item, index) => {
            // if there's no button Continue and user clicks on already selected answer then we should move to the next step
            if (isSingleChoice) {
              if (selected !== index) {
                formik.setFieldValue(name, item.value);
                // need to send custom formik props in this case because it's not sure if formik.setFieldValue above will finish before handleSubmit is called so we send updated formik values
                return handleSubmit({ customFormikProps: { ...formik, values: { ...formik.values, [name]: item.value } } });
              }
              return handleSubmit();
            }
            formik.setFieldValue(name, item.value);
          };

          return (
            <GridItem key={name} area={area} className={item.gridItemClassName}>
              <FormControl
                label={label}
                caption={formik.touched[name] && formik.errors[name]}
                error={Boolean(formik.errors[name] && formik.touched[name])}
                disabled={disabled || formik.isSubmitting}
                htmlFor={name}
                errorPosition={constants.ERROR_MESSAGE_PLACEMENT.center}
              >
                {item?.icon && item.icon}
                {item.hasSearch && (
                  <Input
                    type="text"
                    name={`search-${name}`}
                    value={filter.value}
                    onChange={e => filterOnChange(e, allOptions)}
                    id={`search-${name}`}
                    icon={<StyledSearchIcon size={24} color="#7F93BC" />}
                    isBasic={true}
                    labelAdditional={item.searchLabel}
                  />
                )}
                ;
                <ButtonGroup kind={kind} selected={selected} overrideElementNumber={allOptions.length}>
                  {fieldOptions.map((item, index) => {
                    return (
                      <Button name={name} onBlur={formik.handleBlur} isSelected={selected === index} type="button" kind={kind} key={item.value} onClick={() => handleClick(item, index)}>
                        {handleOptionLabelText(item)}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </FormControl>
            </GridItem>
          );
        } else if (item.type === constants.INPUT_TYPES.checkbox) {
          const fieldOptions = options[name] || item.options;

          function handleChange(value, item, checked) {
            if (checked) {
              return formik.setFieldValue(
                name,
                value.filter(element => element.value !== item.value)
              );
            }

            if (value) {
              return formik.setFieldValue(name, [...value, item]);
            }

            return formik.setFieldValue(name, [item]);
          }

          return (
            <GridItem key={name} area={area} className={item.gridItemClassName}>
              <FormControl label={label} caption={formik.touched[name] && formik.errors[name]} error={Boolean(formik.errors[name] && formik.touched[name])} disabled={disabled} htmlFor={name}>
                {fieldOptions.map(item => {
                  const checked = formik.values[name] && formik.values[name].some(element => element.value === item.value && element.label === item.label);

                  return (
                    <Checkbox key={item.label} name={name} value={item.value} checked={checked} onChange={() => handleChange(formik.values[name], item, checked)} onBlur={formik.handleBlur}>
                      {handleOptionLabelText(item)}
                    </Checkbox>
                  );
                })}
              </FormControl>
            </GridItem>
          );
        } else if (item.type === constants.INPUT_TYPES.multicheckbox) {
          const fieldOptions = options[name] || item.options;

          function handleChange(value, item, checked) {
            if (formik.isSubmitting) {
              return;
            }
            if (checked) {
              return formik.setFieldValue(
                name,
                value.filter(element => element.value !== item.value)
              );
            }

            if (value) {
              return formik.setFieldValue(name, [...value, item]);
            }

            return formik.setFieldValue(name, [item]);
          }
          return (
            <GridItem key={name} area={area} className={item.gridItemClassName}>
              <FormControl
                label={label}
                caption={formik.touched[name] && formik.errors[name]}
                error={Boolean(formik.errors[name] && formik.touched[name])}
                disabled={disabled || formik.isSubmitting}
                htmlFor={name}
                errorPosition={constants.ERROR_MESSAGE_PLACEMENT.center}
              >
                <MultiCheckboxWrapper>
                  {fieldOptions.map(item => {
                    const checked = formik.values[name] && formik.values[name].some(element => element.value === item.value && element.label === item.label);
                    return (
                      <MultiCheckbox
                        key={item.label}
                        name={name}
                        value={item.value}
                        checked={checked}
                        disabled={disabled || formik.isSubmitting}
                        onChange={() => handleChange(formik.values[name], item, checked)}
                        onBlur={formik.handleBlur}
                      >
                        {handleOptionLabelText(item)}
                      </MultiCheckbox>
                    );
                  })}
                </MultiCheckboxWrapper>
              </FormControl>
            </GridItem>
          );
        } else if (item.type === constants.INPUT_TYPES.countryPhoneCode) {
          const fieldOptions = options[name] || item.options;
          const error = typeof formik.errors[name] === "string" ? formik.errors[name] : formik.errors[name]?.country_code || formik.errors[name]?.phone_code || formik.errors[name]?.phone;
          const caption = formik.touched[name] && error;
          const additionalInputLabel = labelsAdditional[name] || null;
          const additionalSelectLabel = labelsAdditional["country_phone_code"] || null;

          return (
            <GridItem key={name} area={area} className={item.gridItemClassName}>
              <FormControl label={label} caption={caption} error={Boolean(caption)} disabled={disabled || formik.isSubmitting} htmlFor={name}>
                <PhoneInput
                  name={name}
                  value={formik.values[name]}
                  onChange={e => {
                    handleChange(name, {
                      ...(formik.values[name] || constants.INITIAL_COUNTRY_TELEPHONE_VALUES),
                      phone: e.target.value?.replace(/^0+/, ""),
                    });
                  }}
                  onBlur={() => handleBlur(name)}
                  onCountryChange={country => {
                    handleChange(name, {
                      ...(formik.values[name] || constants.INITIAL_COUNTRY_TELEPHONE_VALUES),
                      country_code: country.code || null,
                      phone_code: country.phone_code || null,
                    });
                  }}
                  additionalInputLabel={additionalInputLabel}
                  additionalSelectLabel={additionalSelectLabel}
                  options={fieldOptions}
                  disabled={disabled}
                />
              </FormControl>
            </GridItem>
          );
        } else if (item.type === constants.INPUT_TYPES.birthday) {
          const labels = labelsAdditional[name] || {};
          const fieldOptions = options[name] || item.options;
          return (
            <GridItem key={name} area={area} className={item.gridItemClassName}>
              <FormControl
                label={label}
                caption={formik.touched[name] && formik.errors[name]}
                error={Boolean(formik.errors[name] && formik.touched[name])}
                disabled={disabled || formik.isSubmitting}
                htmlFor={name}
              >
                <Birthday name={name} value={formik.values[name]} onChange={e => handleChange(name, e)} onBlur={() => handleBlur(name)} options={fieldOptions} labels={labels} />
              </FormControl>
            </GridItem>
          );
        }
        return null;
      })}
      {children}
    </Fields>
  );
}

Step.propTypes = {
  formik: PropTypes.object,
  fields: PropTypes.array,
  templateAreas: PropTypes.string,
  className: PropTypes.string,
  disabledFields: PropTypes.array,
  hiddenFields: PropTypes.array,
  tooltipTitle: PropTypes.string,
  options: PropTypes.object,
  labels: PropTypes.object,
  isLoading: PropTypes.object,
  handleLabel: PropTypes.func,
  handleOptionLabel: PropTypes.func,
  labelsAdditional: PropTypes.object,
  isSingleChoice: PropTypes.bool,
};

Step.defaultProps = {
  templateAreas: "",
  disabledFields: [],
  hiddenFields: [],
  options: {},
  labels: {},
  isLoading: {},
  labelsAdditional: {},
};

export default Step;
