import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";

import themes from "./themes";
import GlobalStyle from "./GlobalStyle";
import constants from "./constants";

import { Select } from "./components/select";
import { FormControl } from "./components/form-control";
import { ImageUploader } from "./components/image-uploader";
import { DatePicker } from "./components/date-picker";
import Tooltip from "./Tooltip";

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    grid-template-columns: 1fr 1fr;
    width: calc(50% - 0.5rem);
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  @media (min-width: ${constants.BREAKPOINTS.SMALL_DEVICES}) {
    grid-template-columns: 1fr 1fr;
  }
`;

function VerifyResidence(props) {
  const {
    darkMode,
    documentTypeLabel,
    porDocument,
    porDocuments,
    porDocumentOnChange,
    porDocumentOnDrop,
    issuingDateLabel,
    issuingDate,
    issuingDateOnChange,
    issuingDateModifiers,
    issuingDateInitialMonth,
    issuingDateInfo,
    porTitle,
    porSubtitle,
    porPositive,
    porError,
    porIsLoading,
    porIsUploaded,
    porCaption,
    documentTypeDisabled,
    issuingDateDisabled,
    issuingDateError,
    porImageUploaderDisabled,
    porOnClose,
    title,
    info,
  } = props;
  const theme = darkMode ? themes.dark : themes.light;

  return (
    <>
      <ThemeProvider theme={theme}>
        <Tooltip label={title} info={info} />
        <Inputs>
          <div>
            <FormControl
              label={documentTypeLabel}
              disabled={documentTypeDisabled}
            >
              <Select
                value={porDocument}
                options={porDocuments}
                onChange={porDocumentOnChange}
              />
            </FormControl>
          </div>
          <div>
            <FormControl
              label={issuingDateLabel}
              disabled={issuingDateDisabled}
              error={issuingDateError}
            >
              <DatePicker
                value={issuingDate}
                onChange={issuingDateOnChange}
                modifiers={issuingDateModifiers}
                initialMonth={issuingDateInitialMonth}
                info={issuingDateInfo}
              />
            </FormControl>
          </div>
        </Inputs>
        <Grid>
          <div>
            <FormControl
              caption={porCaption}
              positive={porPositive}
              error={porError}
              disabled={porImageUploaderDisabled}
            >
              <ImageUploader
                onDrop={porDocumentOnDrop}
                title={porTitle}
                subtitle={porSubtitle}
                onClose={porOnClose}
                isLoading={porIsLoading}
                isUploaded={porIsUploaded}
              />
            </FormControl>
          </div>
        </Grid>
      </ThemeProvider>
      <GlobalStyle />
    </>
  );
}

VerifyResidence.propTypes = {
  darkMode: PropTypes.bool,
  documentTypeLabel: PropTypes.string,
  porDocument: PropTypes.object,
  porDocuments: PropTypes.array,
  porDocumentOnChange: PropTypes.func,
  porDocumentOnDrop: PropTypes.func,
  issuingDateLabel: PropTypes.string,
  issuingDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  issuingDateOnChange: PropTypes.func,
  issuingDateModifiers: PropTypes.object,
  issuingDateInitialMonth: PropTypes.instanceOf(Date),
  issuingDateInfo: PropTypes.string,
  porTitle: PropTypes.string,
  porSubtitle: PropTypes.string,
  porPositive: PropTypes.bool,
  porError: PropTypes.bool,
  porIsLoading: PropTypes.bool,
  porIsUploaded: PropTypes.bool,
  porCaption: PropTypes.string,
  documentTypeDisabled: PropTypes.bool,
  issuingDateDisabled: PropTypes.bool,
  porImageUploaderDisabled: PropTypes.bool,
  porOnClose: PropTypes.func,
  title: PropTypes.string,
};

VerifyResidence.defaultProps = {
  darkMode: false,
  documentTypeLabel: "Document type",
  porDocument: { value: "", label: "" },
  porDocuments: [],
  issuingDateLabel: "Issuing Date",
  issuingDate: "",
  porPositive: false,
  porError: false,
  porIsLoading: false,
  porIsUploaded: false,
  porCaption: "",
  documentTypeDisabled: false,
  issuingDateDisabled: false,
  porImageUploaderDisabled: false,
  title: "2. Verify Residence",
  info: <div>Content</div>,
};

export default VerifyResidence;
