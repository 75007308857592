import { DefaultTheme } from "styled-components";

const typography = {
  type: {
    primary: `"Inter UI", sans-serif`,
  },
  size: {
    paragraphXSmall: "0.75em",
    paragraphSmall: "0.875em",
    paragraphMedium: "1em",
    paragraphLarge: "1.125em",
    headingXSmall: "1.25em",
    headingSmall: " 1.5em",
    headingMedium: "1.75em",
    headingLarge: "2em",
    headingXLarge: "2.25em",
    headingXXLarge: "2.5em",
  },
};
const spacing = {
  borderRadius: {
    default: "3px",
  },
  padding: {
    small: "8px",
    medium: "16px",
    large: "24px",
    xLarge: "48px",
  },
};
const LightTheme: DefaultTheme = {
  naga: {
    colors: {
      primary: "#000000",
      accent: "#3179FF",
      background: "#FFFFFF",
      backgroundPrimary: "#FFFFFF",
      backgroundInversePrimary: "#000000",
      backgroundSecondary: "#F8F9FB",
      contentPrimary: "#000000",
      negative: "#D44333",
      positive: "#3AA76D",
      foreground: "#000000",
      foregroundAlt: "#545454",
      borderFocus: "#000000",
      buttonPrimaryFill: "#3179FF",
      buttonPrimaryBorder: "#3179FF",
      buttonPrimarySelectedFill: "#545454",
      buttonPrimaryText: "#FFFFFF",
      buttonPrimarySelectedText: "#FFFFFF",
      buttonSecondaryFill: "#FFFFFF",
      buttonSecondaryBorder: "#DFE3E9",
      buttonSecondarySelectedFill: "#E2E2E2",
      buttonSecondaryText: "#000000",
      buttonSecondarySelectedText: "#000000",
      buttonDisabledFill: "#F6F6F6",
      buttonDisabledText: "#AFAFAF",
      inputFill: "#FFFFFF",
      inputBorderDefault: "#DFE3E9",
      inputFillDisabled: "#F6F6F6",
      inputTextDisabled: "#AFAFAF",
      inputFillError: "#FDF0EF",
      inputBorderError: "#F4AFA7",
      inputFillPositive: "#F0F9F4",
      inputBorderPositive: "#AEDDC2",
      inputFillActive: "#FFFFFF",
      notificationInfoBackground: "#EEF3FE",
      notificationInfoText: "#1E54B7",
      notificationPositiveBackground: "#F0F9F4",
      notificationPositiveText: "#368759",
      notificationWarningBackground: "#FEF3EF",
      notificationWarningText: "#B45427",
      notificationNegativeBackground: "#FDF0EF",
      notificationNegativeText: "#AE372A",
      modalCloseColor: "#000000",
    },
    typography,
    spacing,
  },
};
const DarkTheme: DefaultTheme = {
  naga: {
    colors: {
      primary: "#FFFFFF",
      accent: "#276EF1",
      background: "#000000",
      backgroundPrimary: "#141722",
      backgroundInversePrimary: "#E2E2E2",
      backgroundSecondary: "#141722",
      contentPrimary: "#E2E2E2",
      negative: "#D44333",
      positive: "#3AA76D",
      foreground: "#CBCBCB",
      foregroundAlt: "#757575",
      borderFocus: "#FFFFFF",
      buttonPrimaryFill: "#3179FF",
      buttonPrimaryBorder: "#3179FF",
      buttonPrimarySelectedFill: "#E2E2E2",
      buttonPrimaryText: "#FFFFFF",
      buttonPrimarySelectedText: "#000000",
      buttonSecondaryFill: "#141722",
      buttonSecondaryBorder: "#20283C",
      buttonSecondarySelectedFill: "#757575",
      buttonSecondaryText: "#FFFFFF",
      buttonSecondarySelectedText: "#FFFFFF",
      buttonDisabledFill: "#292929",
      buttonDisabledText: "#757575",
      inputFill: "#262B3E",
      inputBorderDefault: "#262B3E",
      inputFillDisabled: "#141414",
      inputTextDisabled: "#333333",
      inputFillError: "#5C1D16",
      inputBorderError: "#F4AFA7",
      inputFillPositive: "#1C472F",
      inputBorderPositive: "#3AA76D",
      inputFillActive: "#252B40",
      notificationInfoBackground: "#102C60",
      notificationInfoText: "#A0BFF9",
      notificationPositiveBackground: "#1C472F",
      notificationPositiveText: "#AEDDC2",
      notificationWarningBackground: "#5F2C14",
      notificationWarningText: "#F7BFA5",
      notificationNegativeBackground: "#5C1D16",
      notificationNegativeText: "#F4AFA7",
      modalCloseColor: "#757575",
    },
    typography,
    spacing,
  },
};
export { LightTheme, DarkTheme };
