import React from "react";
import styled from "styled-components";
import { PLACEMENT, StatefulPopover } from "@malcodeman/react-popover";

import InfoIcon from "./icons/Info";

const Label = styled.label`
  display: inline-flex;
  margin-bottom: 16px;
  align-items: center;
  cursor: pointer;
  svg {
    min-width: 16px;
  }
`;

const LabelText = styled.span`
  margin-right: 0.5rem;
  ${(props) => props.theme.typography.font550}
`;

function Tooltip(props) {
  const { info, label } = props;

  return (
    <StatefulPopover placement={PLACEMENT.BOTTOM_START} content={() => info}>
      <Label>
        <LabelText>{label}</LabelText>
        <InfoIcon size={16} />
      </Label>
    </StatefulPopover>
  );
}

export default Tooltip;
