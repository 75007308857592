import React from "react";
import styled, { css, DefaultTheme, useTheme } from "styled-components";

import { buttonSize, buttonKind, buttonProps } from "../../types";
import Spinner from "../spinner/Spinner";

function getFontStyles(props: { size: buttonSize; theme: DefaultTheme }) {
  const { size } = props;

  switch (size) {
    default:
    case "default":
      return props.theme.naga.typography.size.paragraphSmall;
    case "compact":
      return props.theme.naga.typography.size.paragraphXSmall;
    case "large":
      return props.theme.naga.typography.size.paragraphLarge;
  }
}

function getKindStyles(props: {
  kind: buttonKind;
  isSelected: boolean;
  theme: DefaultTheme;
}) {
  const { kind, isSelected } = props;

  switch (kind) {
    default:
    case "primary":
      if (isSelected) {
        return css`
          background-color: ${props.theme.naga.colors
            .buttonPrimarySelectedFill};
          color: ${props.theme.naga.colors.buttonPrimarySelectedText};
          border-color: ${(props) =>
            props.theme.naga.colors.buttonPrimarySelectedFill};
        `;
      }
      return css`
        background-color: ${props.theme.naga.colors.buttonPrimaryFill};
        color: ${props.theme.naga.colors.buttonPrimaryText};
        border-color: ${(props) => props.theme.naga.colors.buttonPrimaryBorder};
      `;
    case "secondary":
      if (isSelected) {
        return css`
          background-color: ${props.theme.naga.colors
            .buttonSecondarySelectedFill};
          color: ${props.theme.naga.colors.buttonSecondarySelectedText};
          border-color: ${(props) =>
            props.theme.naga.colors.buttonSecondarySelectedFill};
        `;
      }
      return css`
        background-color: ${props.theme.naga.colors.buttonSecondaryFill};
        color: ${props.theme.naga.colors.buttonSecondaryText};
        border-color: ${(props) =>
          props.theme.naga.colors.buttonSecondaryBorder};
      `;
  }
}

const StyledButton = styled.button<{
  kind: buttonKind;
  size: buttonSize;
  isSelected: boolean;
  shouldFitContainer: boolean;
  mb: string;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  outline: 0;
  padding-left: ${(props) => props.theme.naga.spacing.padding.large};
  padding-top: ${(props) => props.theme.naga.spacing.padding.small};
  padding-right: ${(props) => props.theme.naga.spacing.padding.large};
  padding-bottom: ${(props) => props.theme.naga.spacing.padding.small};
  width: ${(props) => (props.shouldFitContainer ? "100%" : "auto")};
  :disabled {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.naga.colors.buttonDisabledFill};
    color: ${(props) => props.theme.naga.colors.buttonDisabledText};
    border-color: ${(props) => props.theme.naga.colors.buttonDisabledFill};
  }
  font-size: ${getFontStyles};
  border-radius: ${(props) => props.theme.naga.spacing.borderRadius.default};
  ${(props) =>
    props.mb &&
    css`
      margin-bottom: ${props.mb};
    `}
  ${getKindStyles};
`;

function Button(props: buttonProps): React.ReactElement {
  const {
    kind = "primary",
    size = "default",
    disabled = false,
    isLoading = false,
    isSelected = false,
    shouldFitContainer = false,
    mb,
    children,
    onClick = () => undefined,
  } = props;
  const theme = useTheme();

  function internalOnClick() {
    if (!isLoading) {
      onClick();
    }
  }

  return (
    <StyledButton
      {...props}
      kind={kind}
      size={size}
      disabled={disabled}
      isSelected={isSelected}
      shouldFitContainer={shouldFitContainer}
      mb={mb}
      onClick={internalOnClick}
    >
      {isLoading ? (
        <Spinner
          color={kind === "primary" ? "#FFF" : theme.naga.colors.accent}
        />
      ) : (
        children
      )}
    </StyledButton>
  );
}

export default Button;
