import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";
import { useFormik } from "formik";

import themes from "./themes";
import GlobalStyle from "./GlobalStyle";

import constants from "./constants";

import { Button, KIND } from "./components/button";
import { ParagraphMedium } from "./components/typography";
import Debug from "./Debug";

const Header = styled.header`
  margin-bottom: 1rem;
`;

const Footer = styled.footer`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
`;

function defaultHeader(props) {
  const { totalSteps, stepNumber } = props;

  return (
    <Header>
      <ParagraphMedium>
        Step {stepNumber + 1} of {totalSteps}
      </ParagraphMedium>
    </Header>
  );
}

function defaultFooter(props) {
  const { stepNumber, previous, isSubmitting, isLastStep } = props;

  return (
    <Footer>
      {stepNumber > 0 && (
        <Button onClick={previous} type="button" kind={KIND.secondary}>
          <span>Back</span>
        </Button>
      )}
      <Button disabled={isSubmitting}>
        <span>{isLastStep ? "Submit" : "Next"}</span>
      </Button>
    </Footer>
  );
}

function Wizard(props) {
  const {
    children,
    initialValues,
    onSubmit,
    darkMode,
    debug,
    className,
    enableReinitialize,
    header,
    footer,
    disabledFields,
    hiddenFields,
    tooltipTitle,
    options,
    onChange,
    stepNumber,
    onPrevious,
    onNext,
    isSingleChoice,
    labels,
    isLoading,
    handleLabel,
    handleOptionLabel,
    labelsAdditional,
  } = props;

  const steps = React.Children.toArray(children);
  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;
  const theme = darkMode ? themes.dark : themes.light;

  const formik = useFormik({
    enableReinitialize,
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: step && step.props.validationSchema,
  });

  function handleSubmit(funcProps) {
    const formikData = funcProps?.customFormikProps || formik;
    if (step.props.onSubmit) {
      step.props.onSubmit(formikData);
    }
    if (isLastStep) {
      return onSubmit(formikData);
    } else {
      if (typeof onNext === "function") {
        onNext({ totalSteps, stepNumber, isLastStep, formik: formikData });
      }
      formik.setTouched({});
    }
  }

  function handlePrevious() {
    if (typeof onPrevious === "function") {
      onPrevious({ totalSteps, stepNumber, isLastStep, formik });
    }
  }

  React.useEffect(() => {
    if (typeof onChange === "function") {
      if (!isSingleChoice) {
        onChange(formik);
      }
    }
  }, [formik.values]);

  // validate form on every new step initially in order to disable Submit button if necessary
  React.useEffect(() => {
    if (formik) {
      formik.validateForm();
    }
  }, [stepNumber]);

  React.useEffect(() => {
    const keys = Object.keys(formik.errors);

    if (keys.length > 0 && formik.isSubmitting && !formik.isValidating) {
      const errorElement = document.querySelector(`[for="${keys[0]}"]`);

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors]);

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={formik.handleSubmit} className={className}>
        {typeof header === "function" && header({ totalSteps, stepNumber, formik, isLoading })}
        {React.isValidElement(step) &&
          React.cloneElement(step, {
            formik,
            disabledFields,
            hiddenFields,
            tooltipTitle,
            options,
            labels,
            isLoading,
            handleLabel,
            handleOptionLabel,
            labelsAdditional,
            handleSubmit,
            children:
              typeof footer === "function" &&
              footer({
                stepNumber,
                previous: handlePrevious,
                isSubmitting: formik.isSubmitting,
                isLastStep,
                formik,
              }),
          })}
      </form>
      {debug && constants.IS_DEV && <Debug formik={formik} />}
      <GlobalStyle />
    </ThemeProvider>
  );
}

Wizard.propTypes = {
  fields: PropTypes.array,
  darkMode: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  debug: PropTypes.bool,
  className: PropTypes.string,
  enableReinitialize: PropTypes.bool,
  header: PropTypes.func,
  footer: PropTypes.func,
  disabledFields: PropTypes.array,
  hiddenFields: PropTypes.array,
  tooltipTitle: PropTypes.string,
  options: PropTypes.object,
  onChange: PropTypes.func,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  stepNumber: PropTypes.number,
  labels: PropTypes.object,
  isLoading: PropTypes.object,
  handleLabel: PropTypes.func,
  handleOptionLabel: PropTypes.func,
  labelsAdditional: PropTypes.object,
  isSingleChoice: PropTypes.bool,
};

Wizard.defaultProps = {
  fields: [],
  darkMode: false,
  debug: false,
  enableReinitialize: true,
  header: defaultHeader,
  footer: defaultFooter,
  disabledFields: [],
  hiddenFields: [],
  tooltipTitle: "Help",
  options: {},
  stepNumber: 0,
  labels: {},
  isLoading: {},
  labelsAdditional: {},
};

export default Wizard;
