import React from "react";
import PropTypes from "prop-types";
import DayPickerInput from "react-day-picker/DayPickerInput";
import styled from "styled-components";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import { DateUtils } from "react-day-picker";

import "react-day-picker/lib/style.css";

import { getInputStyles } from "../input/styles";
import InfoIcon from "../../icons/Info";

const StyledDatePicker = styled.div`
  .DayPickerInput {
    width: 100%;
    input {
      ${getInputStyles};
    }
  }
  .DayPickerInput-OverlayWrapper {
    ${(props) => props.theme.typography.font300};
    color: ${(props) => props.theme.colors.contentPrimary};
    line-height: 1;
  }
  .DayPickerInput-Overlay {
    border-radius: ${(props) => props.theme.borders.radius200};
  }
  .DayPicker-wrapper {
    background-color: ${(props) => props.theme.colors.menuFill};
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${(props) => props.theme.colors.accent};
  }
  .DayPicker-Day--disabled {
    opacity: 0.5;
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

const StyledInfoPanel = styled.div`
  padding: 0 0.5rem 1rem 0.5rem;
  display: flex;
  align-items: center;
  text-align: left;
  max-width: 256px;
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.contentSecondary};
  svg {
    min-width: 16px;
    margin-right: 0.5rem;
  }
`;

const Overlay = styled.div`
  background-color: ${(props) => props.theme.colors.menuFill};
`;

const FORMAT = "dd.MM.yyyy";

function DatePicker(props) {
  const {
    value,
    onChange,
    modifiers,
    disabled,
    error,
    initialMonth,
    info,
  } = props;

  function parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    
    if (DateUtils.isDate(parsed) && parsed.getFullYear() >= 1000) {
      return parsed;
    }
    return undefined;
  }

  function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }

  function infoPanel() {
    return (
      <StyledInfoPanel>
        <InfoIcon size={16} />
        <span>{info}</span>
      </StyledInfoPanel>
    );
  }

  function CustomOverlay({ classNames, selectedDay, children, ...props }) {
    return (
      <div className={classNames.overlayWrapper} {...props}>
        <Overlay className={classNames.overlay}>
          {children}
          {info && infoPanel()}
        </Overlay>
      </div>
    );
  }

  return (
    <StyledDatePicker data-naga-components="date-picker" error={error}>
      <DayPickerInput
        {...props}
        value={value}
        onDayChange={onChange}
        dayPickerProps={{
          modifiers,
          initialMonth,
        }}
        inputProps={{ disabled }}
        parseDate={parseDate}
        formatDate={formatDate}
        format={FORMAT}
        placeholder={FORMAT}
        overlayComponent={CustomOverlay}
      />
    </StyledDatePicker>
  );
}

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  modifiers: PropTypes.object,
  initialMonth: PropTypes.instanceOf(Date),
};

export default DatePicker;
